/**
 *  bergbahnen theme JS App
 */

import $ from 'jquery';
import './app';
import { toggleThread } from './src/toggle.thread';
import { captchaValidation } from './src/captcha.validation';
import { threadForm } from './src/thread.form';

document.addEventListener('DOMContentLoaded', function () {
  const version = '0.9.0';
  // console.log(version);

  toggleThread();
  captchaValidation();
  threadForm();
});
