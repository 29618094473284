/**
 *  Thread Form
 *  ask question: save question
 */

const threadForm = function () {
  var btnAskQuestion = $('#btn-ask-question'),
    subject = $('#thread_subject'),
    questionerName = $('#thread_questioner_name'),
    questionerEmail = $('#thread_questioner_email'),
    question = $('#thread_question'),
    agreed = $('#thread_agreed'),
    captcha = $('#ccm-captcha-code'),
    formError = $('#form-error');
  var openModalPod = $('.open-modal-pod'),
    mainContainer = $('.ask-question'),
    formPod = $('.form-pod'),
    formResultPod = $('.form-result-pod');

  if (!btnAskQuestion) return;
  btnAskQuestion.on('click', handleEvent);

  // fillForTest();

  function handleEvent(e) {
    e.preventDefault();

    errorDisplayMsg(''); // clear error msg container
    clearForm(); // remove error classes

    // validate
    if (!validateForm()) return;

    // call the api via jquery ajax
    var url = btnAskQuestion.data('api-caller-button-uri');
    var data = {
      thread_subject: subject.val(),
      thread_questioner_name: questionerName.val(),
      thread_questioner_email: questionerEmail.val(),
      thread_question: question.val(),
      thread_agreed: agreed.is(':checked'),
      ccmCaptchaCode: captcha.val(),
      sc_ts: $('#sc_ts').val(),
      sc_hp: $('#sc_hp').val(),
    };

    console.log(data);

    $.ajax({
      url,
      method: 'POST',
      data: data,
      dataType: 'json',
      beforeSend: function () {
        toggleDisableForm('disabled');
      },
      success: function (rslt) {
        if (!rslt.success) {
          errorDisplayMsg(rslt.msg_error);
          toggleDisableForm('enabled');
          return;
        }

        // success:
        console.log('success');
        resetForm();
        toggleDisableForm('enabled');
        // toggle form with form result
        toggleFormWithFormResult();
      },
      error: function () {
        errorDisplayMsg('Error 500: Internal Server Error.');
        toggleDisableForm('enabled');
      },
    });
  }

  function validateForm() {
    // subject: required
    if (!subject.val()) {
      errorDisplayMsg(subject.data('validation-msg-required'));
      errorClass(subject);
      return false;
    }

    // name: required
    if (!questionerName.val()) {
      errorDisplayMsg(questionerName.data('validation-msg-required'));
      errorClass(questionerName);
      return false;
    }

    // email: valid email
    if (questionerEmail.val() && !validateEmail(questionerEmail.val())) {
      errorDisplayMsg(questionerEmail.data('validation-msg-invalid-email'));
      errorClass(questionerEmail);
      return false;
    }

    // question: required
    if (!question.val()) {
      errorDisplayMsg(question.data('validation-msg-required'));
      errorClass(question);
      return false;
    }

    // agreed: required
    if (!agreed.is(':checked')) {
      errorDisplayMsg(agreed.data('validation-msg-required'));
      errorClass(agreed);
      return false;
    }

    // question: required
    if (!captcha.val()) {
  //    errorDisplayMsg(captcha.data('validation-msg-required'));
  //    errorClass(captcha);
  //    return false;
    }

    return true;
  }

  function errorDisplayMsg(msg) {
    formError.text(msg);
  }

  // add: true: add `error` class, false: remove it
  function errorClass(elem, add) {
    add = add || true;
    add ? elem.addClass('error') : elem.removeClass('error');
  }

  // remove error msg & error claases
  function clearForm() {
    subject
      .add(questionerName)
      .add(questionerEmail)
      .add(question)
      .removeClass('error');
  }

  // clear + remove values
  function resetForm() {
    clearForm();
    // reset
    subject.add(questionerName).add(questionerEmail).add(question).val('');
  }

  // disable/enable form elems
  // disabled: disabled or enables
  function toggleDisableForm(status) {
    status = status || 'disabled';

    var isDisabled = status === 'disabled' ? true : false;

    btnAskQuestion
      .toggleClass('disabled', isDisabled)
      .toggleClass('spinning', isDisabled);
    btnAskQuestion
      .add(subject)
      .add(questionerName)
      .add(questionerEmail)
      .add(question)
      .prop('disabled', isDisabled);
  }

  function toggleFormWithFormResult() {
    formPod.hide();
    formResultPod.show();

    // toggle bubble arrow
    mainContainer.toggleClass(
      'speech-bubble-arrow-left speech-bubble-arrow-right'
    );
  }

  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  function validateEmail(email) {
    var re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function fillForTest() {
    subject.val(63);
    questionerName.val('John Doe');
    questionerEmail.val('john.doe@yahoo.com');
    question.val(
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum at distinctio alias corporis vitae dolorem odit placeat omnis, minus culpa esse voluptatem quidem quia pariatur ut neque facere maiores. Molestiae!'
    );
  }

  // on testing
  // $('#btn-ask-question-open-modal').click();
};

export { threadForm };
