/**
 *  Captcha Validation
 *  add captcha placeholder/validation-msg manually/the api won't allow it
 */

const captchaValidation = function () {
  const containerCaptcha = $('.captcha-container-input');
  const inputCaptcha = $('#ccm-captcha-code');
  if (!containerCaptcha || !inputCaptcha) return;

  inputCaptcha.attr('placeholder', containerCaptcha.attr('placeholder'));
  inputCaptcha.attr(
    'data-validation-msg-required',
    containerCaptcha.attr('data-validation-msg-required')
  );
};

export { captchaValidation };
