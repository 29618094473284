$(function () {
    $(window).on("load resize", function (){
        //under < 14
        var age_1 = $("#main-wrapper .subtitle table tr:first-of-type td:first-of-type + td").text();
        var age_2 = $("#main-wrapper .subtitle table tr:first-of-type td:first-of-type + td + td").text();
        var age_3 = $("#main-wrapper .subtitle table tr:first-of-type td:first-of-type + td + td + td").text();
        $("#main-wrapper .subtitle table tr").each(function(){
            $(this).find("td:first-of-type + td").attr("age", age_1);
            $(this).find("td:first-of-type + td + td").attr("age", age_2);
            $(this).find("td:first-of-type + td + td + td").attr("age", age_3);
            var agexx = age_2 + " " + age_3;
            $(this).find("td:first-of-type + td + td[colspan=3]").attr("age", agexx);

            var agex = age_1 + " " + age_2 + " " + age_3;
            //$(this).find("td:first-of-type + td[colspan=4]").attr("age", agex);
            $(this).find("td[colspan=4]").attr("age", agex);
        })
    })
});


