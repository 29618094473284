/**
 *  Toggle Thread
 */

const toggleThread = function () {
  const toggler = $('*[data-speech-bubble-toggler]');
  if (!toggler) return;

  toggler.on('click', handleEvent);

  function handleEvent(e) {
    e.preventDefault();

    const self = $(this);
    const toggleable = self
      .closest('*[data-speech-bubble-toggle-container]')
      .find('*[data-speech-bubble-toggleable]');
    if (!toggleable) return;

    self
      .toggleClass('disabled')
      .toggleClass('btn-inverse')
      .prop('disabled', 'disabled');
    toggleable.slideToggle('fast', function () {
      self.toggleClass('disabled').prop('disabled', false);
    });
  }
};

export { toggleThread };
